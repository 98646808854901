import React from "react";
import { ChakraProvider } from '@chakra-ui/react'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/home'
import GetInvolved from './pages/get-involved'
import AboutUs from './pages/about-us'
import Candidates from './pages/candidates'
import JobDetail from './pages/job-detail'
import Clients from './pages/clients'

import Header from './components/header';
import Footer from './components/footer';

export default function App() {
  return (
    <main>
      <Router>
        <ChakraProvider>
        <Header/>
        <div>
          <Switch>
            <Route path="/contact-us">
              <GetInvolved />
            </Route>
            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route path="/candidates">
              <Candidates />
            </Route>
            <Route path="/clients">
              <Clients />
            </Route>
            <Route path="/job/:id/:title" component={JobDetail} />
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer/>
        </ChakraProvider>
      </Router>
    </main>
  );
}
